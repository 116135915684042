<template>
  <div class="gradientBK bkplecamov" style="background: #e4e4f9"
    :v-smooth-scroll="{ duration: 10000, offset: -50, container: '#container' }">
    <Header />

    <div class="B1 gradientBK" style="background: #e4e4f9">
      <!-- <div class="Header">v
                <div class="HB1">
                    <img src="../assets/Logo.svg" />
                    <p>THE WEB, DEV & MKT AGENCY</p>
                </div>
                <div class="HB2">
                    <a><p>Contact us</p></a>
                    <img src="../assets/Menu.svg" />
                </div>
            </div>-->
      <div class="B1F2">
        <div class="B1F2_B1">
          <p class="B1T1">This is Legrafica</p>
          <p class="B1Text">
            Estamos conformados por un equipo de diseñadores, desarrolladores,
            mercadólogos y creativos, cada uno con cualidades extraordinarias
            que se refleja en los proyectos y resultados.
          </p>
          <p class="B1Text">
            Tenemos la firme filosofía de evolucionar junto con el mundo
            digital, trayendo siempre ideas que conecten con las tendencias y el
            comportamiento de los internautas.
          </p>
        </div>
        <div class="B1F2_B2">
          <img src="../assets/Frame.png" />
        </div>
      </div>
    </div>

    <div class="B2_1">
      <div class="B2_1b1">
        <p class="B2_1t1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
          data-aos-delay="100">
          Construimos marcas que impactan y conectan con las personas.
        </p>
        <p class="B2_1t12" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
          data-aos-delay="100">
          Experiencias que se traducen en ventas, rentabilidad y trascendencia.
        </p>
        <p class="B2_1t2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
          data-aos-delay="200">
          Bienvenido a Legrafica
        </p>
      </div>
    </div>

    <div class="B2_2">
      <div class="B2_2b0">
        <img class="B2_2b1" src="../assets/igsf.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
          data-aos-duration="1000" data-aos-delay="100" />
        <div class="B2_2b2">
          <div class="B2_2f1">
            <div class="PinkLine" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"></div>
            <p class="B2F1_T1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
              data-aos-delay="100">
              ¿Quiénes somos?
            </p>
            <p class="B2F1_T2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
              data-aos-delay="200">
              Somos una agencia digital con más de 10 años de experiencias,
              historias y casos de éxito.
            </p>
            <p class="B2F1_T3" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
              data-aos-delay="300">
              Nuestro equipo está conformado por personalidades creativas,
              inquietas y talentosas; especialistas en Branding,
              Marketing,Publicidad, SEO, Diseño y Desarrollo Web.
            </p>
          </div>
          <div class="B2_2f2">
            <div class="PinkLine" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
              data-aos-delay="400"></div>
            <p class="B2F2_T1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
              data-aos-delay="400">
              Lo que hacemos
            </p>
            <p class="B2F2_T2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
              data-aos-delay="500">
              Nos dedicamos resolver los problemas de las empresas,
              ofreciéndoles estrategias con un enfoque global e innovador,
              adaptando a sus necesidades.
            </p>
            <ul class="B2F2_T3" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
              data-aos-delay="600">
              <li>
                Monitoreamos medios, gustos y tendencias del mercado local,
                nacional e internacional.
              </li>
              <li>Construimos marcas significativas para las personas.</li>
              <li>Diseñamos contenidos</li>
              <li>Desarrollamos soluciones digitales a su medida.</li>
              <li>Planeamos, ejecutamos y evaluamos campañas publicitarias.</li>
              <li>
                Generamos resultados efectivos, enfocados al ROI y ventas.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="B2_3">
      <div class="B2_3b0">
        <div class="B2_3b1">
          <p class="B2_3T1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
            data-aos-delay="100">
            No matter the question,
            <span>creativity is always the answer.</span>
          </p>
          <p class="B2_3T2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
            data-aos-delay="200">
            Para nosotros, la creatividad es clave para generar valor a las
            marcas, promover el crecimiento de los negocios, mejorar el
            rendimiento de sus ventas y transformar a las personas.
          </p>
          <p class="B2_3T3" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
            data-aos-delay="300">
            Por ello es el condimento indispensable en cada idea, propuesta y
            contenido que realizamos.
          </p>
        </div>
        <img class="B2_3b2" src="../assets/foto1_1.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
          data-aos-duration="700" />
      </div>
    </div>

    <div class="B2">
      <div class="B2F1">
        <div class="B2F1_F1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000">
          <p class="B2F1_Title">Humanidad.</p>
          <p class="B2F1_1t">Sentimos, amamos, anhelamos, deseamos.</p>
          <p class="B2F1_2t">
            La humanidad forma parte de nuestros valores como empresa, guiando
            nuestros proyectos a empatizar y transmitir el mensaje correcto en
            un mundo diverso y evolutivo.
          </p>
        </div>
        <img src="../assets/image8.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
          data-aos-delay="200" />
      </div>
      <div class="B2F2">
        <img class="imgA" src="../assets/image9.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
          data-aos-duration="1000" />
        <div class="B2F1_F2" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
          data-aos-delay="200">
          <p class="B2F2_Title">Inclusión.</p>
          <p class="B2F2_1t">
            En las preguntas random que solemos hacernos de vez en cuando, nos
            cuestionamos si la agencia era inclusiva. No solo nosotros como
            personas, si no en la publicidad que hacemos todos los días..
          </p>
          <p class="B2F2_2t">
            La realidad es que no lo fuimos tanto como pensamos y tenemos mucho
            que mejorar. Tanto en Legrafica como en nuestros proyectos la
            inclusión no se cuestiona, se transmite y se educa.
          </p>
          <p class="B2F2_3t">
            Todos somos importantes, todos valemos, todos pertenecemos.
          </p>
        </div>
        <img class="imgB" src="../assets/image9.png" />
      </div>
      <div class="B2F3">
        <div class="B2F1_F3" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000">
          <p class="B2F3_Title">Vanguardia.</p>
          <p class="B2F3_1t">Tendencias, movimientos.</p>
          <p class="B2F3_2t">
            Unimos nuestras mentes para experimentar y crear contenidos que
            destaquen en su valor estético y funcional para volverlos únicos y
            trascendentes.
          </p>
        </div>
        <img src="../assets/Vanguardia.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
          data-aos-duration="1000" data-aos-delay="200" />
      </div>
      <div class="B2F4">
        <img class="imgA" src="../assets/funcionalidad2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
          data-aos-duration="1000" />
        <div class="B2F1_F4" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000"
          data-aos-delay="200">
          <p class="B2F4_Title">Funcionalidad.</p>
          <p class="B2F4_1t">
            En todos nuestros proyectos buscamos dar una experiencia de usuario
            fácil e inteligente que facilite su camino a los objetivos del
            cliente.
          </p>
          <p class="B2F4_2t">
            Sabemos que el mundo digital está en constante movimiento debido a
            las nuevas herramientas tecnológicas y preferencias en los usuarios,
            por eso nos prometemos a continuar descubriendo y ejecutando mejoras
            día a día.
          </p>
        </div>
        <img class="imgB" src="../assets/funcionalidad2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
          data-aos-duration="1000" />
      </div>
    </div>

    <div class="B3">
      <p class="B3Title" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="1000">
        Meet the team.
      </p>
      <div class="B3F2">
        <div class="B3F2_F1">
          <div class="imageinfo uno" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700">
            <div class="info-hover">
              <p class="compname">Ana Belén</p>
              <p class="areaname">Project Manager</p>
            </div>
            <img src="../assets/image7.png" class="B3B1_img1" />
          </div>
          <div class="imageinfo dos" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700"
            data-aos-delay="100">
            <div class="info-hover">
              <p class="compname">Albeira</p>
              <p class="areaname">Social Media Manager</p>
            </div>
            <img src="../assets/image17.png" class="B3B1_img2" />
          </div>
          <div class="imageinfo tres" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700"
            data-aos-delay="200">
            <div class="info-hover">
              <p class="compname">Brayan</p>
              <p class="areaname">Backend Developer</p>
            </div>
            <img src="../assets/image19.png" class="B3B1_img3" />
          </div>
          <div class="imageinfo cuatro" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700"
            data-aos-delay="200">
            <div class="info-hover">
              <p class="compname">Luis Gerardo</p>
              <p class="areaname">Jr. Frontend Developer</p>
            </div>
            <img src="../assets/image28.png" class="B3B1_img3" />
          </div>
        </div>
        <div class="B3F2_F2">
          <div class="imageinfo cuatro" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700">
            <div class="info-hover">
              <p class="compname">Enrique</p>
              <p class="areaname">COO/CFO</p>
            </div>
            <img src="../assets/image18.png" class="B3B2_img1" />
          </div>
          <div class="imageinfo cinco" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700"
            data-aos-delay="100">
            <div class="info-hover">
              <p class="compname">Aciid</p>
              <p class="areaname">Creative Director</p>
            </div>
            <img src="../assets/image14.png" class="B3B2_img2" />
          </div>
          <div class="imageinfo doce" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700"
            data-aos-delay="100">
            <div class="info-hover">
              <p class="compname">Trini</p>
              <p class="areaname">Community Manager</p>
            </div>
            <img src="../assets/image16.png" class="B3B2_img2" />
          </div>
        </div>
        <div class="B3F2_F3">
          <div class="imageinfo seis" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700">
            <div class="info-hover">
              <p class="compname">Luis</p>
              <p class="areaname">CEO</p>
            </div>
            <img src="../assets/image15.png" class="B3B3_img1" />
          </div>
          <div class="imageinfo siete" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700"
            data-aos-delay="100">
            <div class="info-hover">
              <p class="compname">Eduardo</p>
              <p class="areaname">Jr. Frontend Developer</p>
            </div>
            <img src="../assets/image22.png" class="B3B3_img2" />
          </div>
          <div class="imageinfo ocho" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700"
            data-aos-delay="200">
            <div class="info-hover">
              <p class="compname">Erik</p>
              <p class="areaname">CTO</p>
            </div>
            <img src="../assets/image23.png" class="B3B3_img3" />
          </div>
        </div>
        <div class="B3F2_F4">
          <div class="imageinfo nueve" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700"
            data-aos-delay="100">
            <div class="info-hover">
              <p class="compname">Daniela</p>
              <p class="areaname">SEM/SEO Media Manager</p>
            </div>
            <img src="../assets/image21.png" class="B3B4_img1" />
          </div>
          <div class="imageinfo diez" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700">
            <div class="info-hover">
              <p class="compname">Danitza</p>
              <p class="areaname">UI/UX Director</p>
            </div>
            <img src="../assets/image20.png" class="B3B4_img2" />
          </div>
          <div class="imageinfo once" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700"
            data-aos-delay="200">
            <div class="info-hover">
              <p class="compname">Gaby</p>
              <p class="areaname">Designer</p>
            </div>
            <img src="../assets/image24.png" class="B3B4_img3" />
          </div>
          <div class="imageinfo doce" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700"
            data-aos-delay="200">
            <div class="info-hover">
              <p class="compname">Alejandro</p>
              <p class="areaname">Designer</p>
            </div>
            <img src="../assets/image26.png" class="B3B4_img3" />
          </div>
        </div>
      </div>
      <div class="B3F3">
        <div class="B3F2_F1">
          <img src="../assets/image7_2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
            data-aos-duration="700" />
          <img src="../assets/image21_2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
            data-aos-duration="700" />
          <img src="../assets/image14_2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
            data-aos-duration="700" />
          <img src="../assets/image17_2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
            data-aos-duration="700" />
          <img src="../assets/image15_2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
            data-aos-duration="700" />
          <img src="../assets/image24_2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
            data-aos-duration="700" />
          <img src="../assets/image22_2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
            data-aos-duration="700" />
          <img src="../assets/image26_2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
            data-aos-duration="700" />
          <img src="../assets/image23_2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
            data-aos-duration="700" />
          <img src="../assets/image28_2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
            data-aos-duration="700" />
          <img src="../assets/image20_2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
            data-aos-duration="700" />
          <img src="../assets/image19_2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
            data-aos-duration="700" />
          <img src="../assets/image16_2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
            data-aos-duration="700" />
          <img src="../assets/image18_2.png" data-aos="fade-zoom-in" data-aos-easing="ease-in-back"
            data-aos-duration="700" />
        </div>
      </div>
      <p class="B3Desc" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700">
        Estás a punto de ser respaldado por un equipo de expertos en campañas
        publicitarias, desarrollo web y aplicaciones móviles.
        <span>¿Empezamos?</span>
      </p>
      <p class="B3Start" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="700">
        ¿Empezamos?
      </p>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/on-site/Header.vue";
import Footer from "../components/on-site/Footer.vue";

export default {
  name: "About",
  components: {
    Header,
    Footer,
  },
  methods: {
    changeBackgroundPartners(event) {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let divcont = document.getElementsByClassName("gradientBK")[0];
      let divcont1 = document.getElementsByClassName("gradientBK")[1];
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      if (media768.matches) {
        if (offset <= 57) {
          divcont.style.backgroundColor = "#E4E4F9";
          divcont1.style.backgroundColor = "#E4E4F9";
        }
        if (offset >= 57) {
          divcont.style.backgroundColor = "#FFFFFF";
          divcont1.style.backgroundColor = "#FFFFFF";
        }
      } else if (min768.matches) {
        /**#e4e4f9 */
        if (offset <= 57) {
          divcont.style.backgroundColor = "#E4E4F9";
          divcont1.style.backgroundColor = "#E4E4F9";
        }
        if (offset >= 57) {
          divcont.style.backgroundColor = "#FFFFFF";
          divcont1.style.backgroundColor = "#FFFFFF";
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.changeBackgroundPartners);
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'About - Legrafica';
      }
    },
  }
};
</script>

<style scoped>
.B1 {
  width: 100%;
  /* height: 61.71875VW; */
  background: #e4e4f9;
  padding-top: 22vh;
  padding-bottom: 3.3333333333333335vw;
}

.Header {
  display: flex;
  width: 92.5vw;
  height: 10.572916666666666vw;
  padding: 0vw 3.28125vw;
  margin: 0vw;
  justify-content: space-between;
}

.HB1 {
  display: flex;
}

.HB1 img {
  width: 10.260416666666666vw;
  height: auto;
  margin-right: 2.5vw;
  margin-bottom: -6vw;
}

.HB1 p {
  color: #ef67c2;
  font-size: 0.5208333333333333vw;
  align-self: center;
}

.HB2 {
  display: flex;
}

.HB2 a {
  align-self: center;
}

.HB2 p {
  color: #987ef3;
  font-size: 1.1458333333333333vw;
  align-self: center;
}

.HB2 img {
  width: 3.90625vw;
  height: auto;
  margin-left: 2.5vw;
}

.B1F2_B1 {
  margin-left: 8.59375vw;
}

.B1F2 {
  display: flex;
}

.B1T1 {
  color: #ef67c2;
  font-size: 7.8125vw;
  font-family: "Gramatika-Medium";
  margin: 0vw;
  margin-bottom: 5.364583333333333vw;
}

.B1T2 {
  width: 34vw;
  color: #987ef3;
  font-size: 5.208333333333334vw;
  line-height: 4.234375vw;
  font-family: "Gramatika-Medium";
  margin-top: 0vw;
}

p.B1Text {
  width: 30vw;
  font-size: 0.8333333333333334vw;
  line-height: 1.4984374999999999vw;
  font-family: "IBM Plex Mono";
  margin: 0;
  margin-bottom: 2vw;
}

.B1F2_B2 {
  width: 100%;
}

.B1F2_B2 img {
  width: 54.708333vw;
  margin-top: -11vw;
  float: right;
}

.B2_1 {
  display: flex;
  height: 48.125vw;
  justify-content: center;
  align-items: center;
}

.B2_1b1 {
  width: 59vw;
  text-align: -webkit-center;
}

p.B2_1t1,
.B2_1t12 {
  font-size: 1.8229166666666667vw;
  line-height: 2.7765625000000003vw;
  margin: 0vw;
  font-family: "Gramatika-Medium";
}

p.B2_1t2 {
  font-size: 1.8229166666666667vw;
  line-height: 2.7765625000000003vw;
  margin-top: 3vw;
  font-family: "Gramatika-Medium";
}

.B2_2f1 {
  margin-bottom: 4vw;
}

p.B2F1_T1 {
  font-size: 1.0416666666666665vw;
  font-weight: 700;
  font-family: "IBM Plex Mono";
  margin-bottom: 3vw;
}

p.B2F1_T2 {
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-family: "IBM Plex Mono";
  margin-bottom: 1vw;
}

p.B2F1_T3 {
  width: 40vw;
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-family: "IBM Plex Mono";
}

.B2_2 {
  display: flex;
  justify-content: center;
  margin-bottom: 27.864583333333332vw;
}

img.B2_2b1 {
  width: 37.708333333333336vw;
  margin-right: 8.229166666666667vw;
  margin-top: -4vw;
}

.B2_2b0 {
  display: flex;
}

.PinkLine {
  width: 3.125vw;
  height: 0.13vw;
  background-color: #ffb6ea;
  margin-left: -5vw;
}

p.B2F2_T1 {
  font-size: 1.0416666666666665vw;
  font-weight: 700;
  font-family: "IBM Plex Mono";
  margin-bottom: 3vw;
}

p.B2F2_T2 {
  width: 41vw;
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-family: "IBM Plex Mono";
  margin-bottom: 1vw;
}

.B2F2_T3 li {
  width: 40vw;
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-family: "IBM Plex Mono";
}

.B2_3 {
  display: flex;
  justify-content: center;
}

.B2_3b0 {
  display: flex;
  align-items: center;
}

.B2_3T1 {
  width: 34vw;
  font-size: 5.208333333333334vw;
  line-height: 5.067708333333333vw;
  color: #987ef3;
  font-family: "Gramatika-Medium";
}

.B2_3T1 span {
  color: #ffb6ea;
}

p.B2_3T2 {
  width: 30vw;
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-weight: 400;
  font-family: "IBM Plex Mono";
}

p.B2_3T3 {
  width: 30vw;
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-weight: 400;
  font-family: "IBM Plex Mono";
}

img.B2_3b2 {
  width: 30.46875vw;
  height: 39.01vw;
}

.B2F1 {
  display: flex;
  justify-content: center;
  margin-bottom: 21.71875vw;
}

p.B2F1_Title {
  color: #b2acfc;
  font-size: 1.5625vw;
  font-family: "Gramatika-Medium";
}

p.B2F1_1t {
  width: 15vw;
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-weight: 400;
  font-family: "IBM Plex Mono";
  margin-left: 10vw;
}

p.B2F1_2t {
  width: 15vw;
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-weight: 400;
  font-family: "IBM Plex Mono";
  margin-left: 10vw;
}

.B2F1 img {
  width: 22.447916666666668vw;
  margin-left: 9.53125vw;
}

.B2F2 {
  display: flex;
  justify-content: center;
  margin-bottom: 21.71875vw;
}

p.B2F2_Title {
  color: #b2acfc;
  font-size: 1.5625vw;
  font-family: "Gramatika-Medium";
}

p.B2F2_1t {
  width: 21vw;
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-weight: 400;
  font-family: "IBM Plex Mono";
  margin-left: 4vw;
}

p.B2F2_2t {
  width: 21vw;
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-weight: 400;
  font-family: "IBM Plex Mono";
  margin-left: 4vw;
}

p.B2F2_3t {
  width: 21vw;
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-weight: 400;
  font-family: "IBM Plex Mono";
  margin-left: 4vw;
}

.B2F2 img {
  width: 22.447916666666668vw;
  margin-right: 11.354166666666666vw;
  height: 21.510416666666668vw;
}

.B2F3 {
  display: flex;
  justify-content: center;
  margin-bottom: 21.71875vw;
}

p.B2F3_Title {
  color: #b2acfc;
  font-size: 1.5625vw;
  font-family: "Gramatika-Medium";
}

p.B2F3_1t {
  width: 15vw;
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-weight: 400;
  font-family: "IBM Plex Mono";
  margin-left: 10vw;
}

p.B2F3_2t {
  width: 15vw;
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-weight: 400;
  font-family: "IBM Plex Mono";
  margin-left: 10vw;
}

.B2F3 img {
  width: 22.447916666666668vw;
  margin-left: 9.53125vw;
}

.B2 {
  padding-top: 33.541666666666664vw;
}

.B2F4 {
  display: flex;
  justify-content: center;
  margin-bottom: 21.71875vw;
}

p.B2F4_Title {
  color: #b2acfc;
  font-size: 1.5625vw;
  font-family: "Gramatika-Medium";
}

p.B2F4_1t {
  width: 21vw;
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-weight: 400;
  font-family: "IBM Plex Mono";
  margin-left: 4vw;
}

p.B2F4_2t {
  width: 21vw;
  font-size: 0.78125vw;
  line-height: 1.4046874999999999vw;
  font-weight: 400;
  font-family: "IBM Plex Mono";
  margin-left: 4vw;
}

.B2F4 img {
  width: 22.447916666666668vw;
  margin-right: 11.354166666666666vw;
}

p.B3Title {
  font-size: 5.208333333333334vw;
  color: #987ef3;
  font-weight: 500;
  margin-left: 7.916666666666666vw;
  margin-bottom: 9.322916666666666vw;
  font-family: "Gramatika-Medium";
}

.B3F2 {
  display: flex;
  justify-content: center;
}

.imageinfo {
  overflow: hidden;
  display: flex;
}

.imageinfo:hover .info-hover {
  opacity: 1;
  pointer-events: all;
}

.imageinfo img {
  transition: 0.5s;
}

.imageinfo:hover img {
  transform: scale(1.1);
}

.info-hover {
  opacity: 0;
  position: absolute;
  width: 18.75vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition: 0.5s;
  background: rgba(162, 136, 255, 0.8);
  z-index: 10;
}

.compname {
  margin: 0 0 0.885vw;
  font-family: "Gramatika-Medium";
  font-size: 1.302vw;
  line-height: 81.3%;
  color: #ffffff;
  text-align: center;
}

p.areaname {
  margin: 0;
  font-family: "Gramatika-Medium";
  font-size: 0.781vw;
  line-height: 81.3%;
  color: #ffe2f7;
  text-align: center;
}

.B3F2_F1 {
  /* margin-top: 2.083vw; */
  /**width: 19vw; */
  display: grid;
  grid-auto-rows: max-content;
}

.imageinfo.uno {
  width: 18.75vw;
  /* height: 18.75vw; */
  /* margin-left: 1.146vw;
  margin-bottom: 2.604vw; */
}

img.B3B1_img1 {
  width: 18.75vw;
  height: auto;
}

.imageinfo.dos {
  width: 18.75vw;
  margin: 1.9270833333333335VW 0 2.2395833333333335VW -1.09375VW;
}

img.B3B1_img2 {
  width: 18.75vw;
  height: auto;
}

.imageinfo.tres {
  width: 18.75vw;
  margin: 0 0 1.5625VW -2.34375VW;
}

img.B3B1_img3 {
  width: 18.75vw;
  height: auto;
}

.B3F2_F2 {
  /**width: 19vw; */
  display: grid;
  grid-auto-rows: max-content;
  margin-top: 11.03515625VW;
  margin-left: 0.052083333333333336VW;
}

.imageinfo.cuatro {
  width: 18.75vw;
  height: auto;
}

.B3F2_F1 .imageinfo.cuatro {
  margin: 0 0 0 -1.09375VW;
}

.B3F2_F2 .imageinfo.cuatro {
  margin: 0 0 1.7187500000000002VW 1.1979166666666667VW;
}

img.B3B2_img1 {
  width: 18.75vw;
  /* height: 100%; */
}

.imageinfo.cinco {
  width: 18.75vw;
  margin: 0 0 1.8229166666666667VW 0.9375VW;
}

img.B3B2_img2 {
  width: 18.75vw;
}

.B3F2_F3 {
  margin-left: 1.1979166666666667VW;
  display: grid;
  grid-auto-rows: max-content;
}

.imageinfo.seis {
  width: 18.75vw;
  /* height: 35.833vw; */
  /* margin-left: 1.406vw; */
  /* margin-bottom: 1.042vw; */
}

img.B3B3_img1 {
  width: 18.75vw;
}

.imageinfo.siete {
  width: 18.75vw;
  margin: 1.7708333333333333VW 0 1.9791666666666665VW 1.1458333333333333VW;
}

img.B3B3_img2 {
  width: 18.75vw;
}

.imageinfo.ocho {
  /* margin-left: 1.302vw; */
  width: 18.75vw;
  /* height: 18.75vw; */
}

img.B3B3_img3 {
  width: 18.75vw;
}

.B3F2_F4 {
  /**width: 19vw; */
  display: grid;
  grid-auto-rows: max-content;
  margin-top: 2.7083333333333335VW;
}

.imageinfo.nueve {
  width: 18.75vw;
  margin-left: 0.7291666666666666VW;
}

img.B3B4_img1 {
  width: 18.75vw;
}

.imageinfo.diez {
  width: 18.75vw;
  margin: 1.0416666666666665VW 0 1.0416666666666665VW 1.5104166666666667VW;
}

img.B3B4_img2 {
  width: 18.75vw;
}

.imageinfo.once {
  width: fit-content;
  margin: 0 0 2.4479166666666665VW 2.34375VW;
  /* height: 18.75vw; */
  /* margin-left: 1.094vw; */
}

img.B3B2_img3 {
  width: 18.75vw;
}

.imageinfo.doce {
  width: 18.75vw;
  /* height: 18.75vw; */
  /* margin-top: 1.042vw; */
  /* margin-left: 1.094vw; */
}

.once img {
  width: 17.135416666666668VW;
}

p.B3Desc {
  width: 58.854vw;
  font-size: 1.5625vw;
  line-height: 179.8%;
  /*line-height: 2.8093749999999997VW;*/
  text-align: center;
  margin: 26.718750000000004vw auto 25.052083333333336vw;
  font-family: "Gramatika-Regular";
}

.doce img {
  width: 18.75vw;
}

.B3Desc span {
  color: #ef67c2;
}

/*img.B3B4_img3 {
    width: 18.75VW;
}*/

.imgB {
  display: none;
}

.B3F3 {
  display: none;
}

p.B3Start {
  display: none;
}

@media (max-width: 768px) {
  .B1 {
    width: 100%;
    height: auto;
    background-color: linear-gradient(#e4e4f9 80%, #ffffff);
    background-image: url(../assets/pleca_about.png) !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100%;
    padding-top: 13vh;
  }

  .B1F2 {
    margin-top: 14.705314vw;
  }

  .HB1 p {
    display: none;
  }

  .HB1 img {
    width: 33.260417vw;
    margin-bottom: -33vw;
    margin-left: 6.487922705314009vw;
  }

  .HB2 p {
    display: none;
  }

  .HB2 img {
    width: 14.009661835748794vw;
    margin-left: 2.5vw;
    margin-bottom: -11vw;
    margin-right: 6.487922705314009vw;
  }

  .B1F2_B2 {
    display: none;
  }

  .B1F2_B1 {
    margin-left: 14.492753623188406vw;
  }

  .B1T1 {
    font-size: 12.077294685990339vw;
    width: 85%;
    line-height: 14.64975845410628vw;
    margin-bottom: 21.768115942028986vw;
  }

  .B1T2 {
    width: 79vw;
    font-size: 12.077294685990339vw;
    line-height: 13.20048309178744vw;
  }

  p.B1Text {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.777777777777778vw;
    margin: 0;
    margin-bottom: 5vw;
  }

  p.B1Text:last-child {
    margin-bottom: 30.917874396135264vw;
  }

  .B2_1 {
    height: 50.434783vw;
    display: block;
    justify-content: unset;
    align-items: unset;
  }

  .B2_1b1 {
    width: 72%;
    margin: auto;
  }

  p.B2_1t1,
  p.B2_1t12 {
    font-size: 4.830917874396135vw;
    line-height: 7.357487922705314vw;
    font-family: "Gramatika-medium";
  }

  p.B2_1t1 {
    margin-bottom: 6vw;
  }

  p.B2_1t2 {
    font-size: 4.830917874396135vw;
    line-height: 7.357487922705314vw;
    margin-top: 6vw;
  }

  .B2_2 {
    margin-left: 14.492753623188406vw;
  }

  .B2_2b0 {
    display: block;
  }

  img.B2_2b1 {
    width: 78.50241545893721vw;
    margin-right: 0vw;
    margin-top: 20vw;
  }

  img.B2_2b1 {
    margin-bottom: 22.22222222222222vw;
  }

  .PinkLine {
    width: 10.869565217391305vw;
    margin-left: 0vw;
  }

  p.B2F1_T1 {
    font-size: 3.381643vw;
    margin-bottom: 6vw;
    margin-top: 8.695652173913043vw;
  }

  p.B2F1_T2 {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.777777777777778vw;
    margin-bottom: 2vw;
  }

  p.B2F1_T3 {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.777777777777778vw;
    margin-bottom: 10vw;
  }

  p.B2F2_T1 {
    font-size: 3.381643vw;
    margin-bottom: 6vw;
  }

  p.B2F2_T2 {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.777777777777778vw;
    margin-bottom: 2vw;
  }

  ul.B2F2_T3 {
    padding-left: 5vw;
  }

  .B2F2_T3 li {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.777777777777778vw;
  }

  .B2_3b0 {
    display: block;
    margin-left: 14.492753623188406vw;
  }

  .B2_3T1 {
    width: 70%;
    font-size: 10.869565217391305vw;
    line-height: 12.043478260869565vw;
    margin: 0;
    margin-bottom: 20vw;
  }

  p.B2_3T2 {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.777777777777778vw;
  }

  p.B2_3T3 {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.777777777777778vw;
    margin-top: 5vw;
  }

  img.B2_3b2 {
    width: 77.05314009661835vw;
    margin-top: 20vw;
    height: auto;
  }

  .imgA {
    display: none;
  }

  .imgB {
    display: block;
  }

  .B2F1 {
    display: block;
    justify-content: center;
    margin-bottom: 21.71875vw;
    margin-left: 14.251207729468598vw;
  }

  p.B2F1_Title {
    font-size: 4.830917874396135vw;
    margin-bottom: 9.178743961352657vw;
  }

  p.B2F1_1t {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.77vw;
    margin-left: 0vw;
    margin-bottom: 4vw;
    margin-top: 0;
  }

  p.B2F1_2t {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.77vw;
    margin-left: 0vw;
  }

  .B2F1 img,
  .B2F3 img {
    width: 60.86956521739131vw;
    margin-left: 9vw;
    margin-top: 15vw;
  }

  .B2F2 {
    display: block;
    justify-content: center;
    margin-bottom: 21.71875vw;
    margin-left: 14.251207729468598vw;
  }

  p.B2F2_Title {
    font-size: 4.830917874396135vw;
    margin-bottom: 9.178743961352657vw;
  }

  p.B2F2_1t {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.77vw;
    margin-left: 0vw;
    margin-bottom: 4vw;
  }

  p.B2F2_2t {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.77vw;
    margin-left: 0vw;
  }

  p.B2F2_3t {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.77vw;
    margin-left: 0vw;
  }

  .B2F2 img {
    width: 60.86956521739131vw;
    height: 58.212560386473434vw;
    margin-left: -2vw;
    margin-top: 15vw;
  }

  .B2F3 {
    display: block;
    justify-content: center;
    margin-bottom: 21.71875vw;
    margin-left: 14.251207729468598vw;
  }

  p.B2F3_Title {
    font-size: 4.830917874396135vw;
    margin-bottom: 10.628019323671497vw;
  }

  p.B2F3_1t {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.77vw;
    margin-left: 0vw;
    margin-bottom: 4vw;
  }

  p.B2F3_2t {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.77vw;
    margin-left: 0vw;
  }

  .B2F4 {
    display: block;
    justify-content: center;
    margin-bottom: 42.99516908212561vw;
    margin-left: 14.251207729468598vw;
  }

  p.B2F4_Title {
    font-size: 4.830917874396135vw;
    margin-bottom: 10.628019323671497vw;
  }

  p.B2F4_1t {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.77vw;
    margin-left: 0vw;
    margin-bottom: 4vw;
  }

  p.B2F4_2t {
    width: 85%;
    font-size: var(--size11mov);
    line-height: 4.77vw;
    margin-left: 0vw;
  }

  .B2F4 img {
    width: 60.86956521739131vw;
    margin-left: -2vw;
    margin-top: 15vw;
  }

  .B3F2 {
    display: none;
  }

  .B3F3 {
    display: block;
  }

  .B3F2_F1 {
    display: grid;
    grid-template-columns: 39.61352657004831VW auto;
    margin: 0 8.937198067632849VW 0 10.869565217391305VW;
  }

  .B3F2_F1>img:nth-of-type(1) {
    width: 47.58454106280193VW;
    grid-column: span 2;
  }

  .B3F2_F1>img:nth-of-type(2) {
    width: 40.82125603864734VW;
    grid-column: span 2;
    margin: 5.314009661835748VW 0 3.864734299516908VW 33.091787439613526VW;
  }

  .B3F2_F1>img:nth-of-type(3) {
    width: 39.61352657004831VW;
    margin: 7.971014492753622VW 0 0 0;
    object-fit: cover;
  }

  .B3F2_F1>img:nth-of-type(4) {
    width: 38.64734299516908VW;
    margin: 0 0 6.763285024154589VW 2.1739130434782608VW;
    object-fit: cover;
  }

  .B3F2_F1>img:nth-of-type(5) {
    width: 75.60386473429952VW;
    grid-column: span 2;
    margin: 0 1.932367149758454VW 4.1062801932367154VW auto;
  }
  .B3F2_F1>img:nth-of-type(6) {
    width: 36.95652173913043VW;
    margin: 7.246376811594203VW 0 0 3.140096618357488VW;
  }
  .B3F2_F1>img:nth-of-type(7) {
    width: 38.88888888888889VW;
    margin: 0 0 0 3.140096618357488VW;
  }
  .B3F2_F1>img:nth-of-type(8) {
    width: 34.05797101449276VW;
    grid-column: 2 / 3;
    margin: -8.695652173913043VW 0 5.072463768115942VW 4.3478260869565215VW;
  }
  .B3F2_F1>img:nth-of-type(9) {
    width: 69.80676328502415VW;
    grid-column: span 2;
    margin: 0 0 4.1062801932367154VW 6.763285024154589VW;
  }

  .B3F2_F1>img:nth-of-type(10) {
    width: 34.78260869565217VW;
    margin: 7.729468599033816VW 0 0 4.1062801932367154VW;
  }

  .B3F2_F1>img:nth-of-type(11) {
    width: 34.78260869565217VW;
    margin: 0 0 0 2.4154589371980677VW;
  }

  .B3F2_F1>img:nth-of-type(12) {
    width: 31.40096618357488VW;
    margin: -14.009661835748794VW 0 0 4.1062801932367154VW;
  }

  .B3F2_F1>img:nth-of-type(13) {
    width: 31.88405797101449VW;
    margin: 15.458937198067632VW 0 0 1.4492753623188406VW;
  }

  .B3F2_F1>img:nth-of-type(14) {
    width: 69.80676328502415VW;
    margin: 11.83574879227053VW 0 0 6.763285024154589VW;
  }

  img.B3B1_img1 {
    width: 47.58454106280193vw;
    height: 47.58454106280193vw;
  }

  img.B3B2_img1 {
    width: 40.82125603864734vw;
    height: 40.82125603864734vw;
    margin-top: 5.314009661835748vw;
    margin-bottom: 3.864734299516908vw;
    margin-left: 33vw;
  }

  img.B3B1_img2 {
    width: 39.61352657004831vw;
    height: 71.49758454106279vw;
    margin-top: 8vw;
    margin-right: 2.1739130434782608vw;
  }

  img.B3B1_img3 {
    width: 38.64734299516908vw;
    height: 100.72463768115942vw;
  }

  img.B3B2_img2 {
    width: 75.60386473429952vw;
    height: 80.67632850241546vw;
    margin-top: 6.763285024154589vw;
    margin-bottom: 4.1062801932367154vw;
  }

  img.B3B4_img1 {
    width: 36.95652173913043vw;
    height: 56.52173913043478vw;
    margin-top: 3vw;
    margin-right: 3.140096618357488vw;
  }

  img.B3B3_img2 {
    width: 38.88888888888889vw;
    height: 47.82608695652174vw;
  }

  img.B3B3_img1 {
    width: 69.80676328502415vw;
    height: 83.09178743961353vw;
    margin-top: 7.246376811594203vw;
    margin-bottom: 4.1062801932367154vw;
    margin-left: 2.62vw;
  }

  img.B3B4_img3 {
    width: 34.78260869565217vw;
    height: 37.43961352657005vw;
    margin-top: 7vw;
  }

  img.B3B4_img2 {
    width: 34.78260869565217vw;
    height: 65.94202898550725vw;
    margin-left: 2.657004830917874vw;
  }

  img.B3B4_img4 {
    width: 34.78260869565217vw;
    height: 37.43961352657005vw;
    margin-top: -18vw;
  }

  p.B3Desc {
    width: 63%;
    font-size: 4.3478260869565215vw;
    line-height: 7.816425120772946vw;
    text-align: center;
    margin-top: 30.434782608695656vw;
    margin-bottom: 10vw;
    font-weight: 600;
  }

  .B3Desc span {
    display: none;
  }

  p.B3Start {
    display: block;
    font-size: 4.3478260869565215vw;
    line-height: 7.816425120772946vw;
    text-align: center;
    color: #ef67c2;
    margin-bottom: 35.024vw;
  }

  .PinkLine {
    margin-bottom: 8.695652173913043vw;
  }

  p.B3Title {
    font-size: 10.869565217391305vw;
    margin-left: 10.869565217391305vw;
    margin-bottom: 26.570048309178745vw;
  }

  .B3B5_img1 {
    width: 31.40096618357488vw;
    height: 54.347826086956516vw;
    margin-top: -15vw;
  }

  .B3B5_img2 {
    width: 31.88405797101449vw;
    height: 44.44444444444444vw;
    margin-left: 5.15vw;
    margin-top: 15.458937198067632vw;
  }
}
</style>
